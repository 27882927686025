/// <reference types="@angular/localize" />
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from "@angular/core";

// Extensions must be imported here even though they are not directly used in this file.
import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { MAT_CARD_CONFIG } from "@angular/material/card";
import {
  ErrorStateMatcher,
  provideNativeDateAdapter,
  ShowOnDirtyErrorStateMatcher,
} from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  provideRouter,
  Routes,
  TitleStrategy,
  withComponentInputBinding,
  withInMemoryScrolling,
} from "@angular/router";
import "@shared/helpers/ArrayHelper";
import "@shared/helpers/DateHelpers";
import "@shared/helpers/StringExtensions";
import "@shared/helpers/TimeSpanToString";
import { MyCustomPaginatorIntl } from "@shared/localized.pagnator";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { Angular2PromiseButtonModule } from "angular2-promise-buttons";
import { RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

import { PlatformLocation } from "@angular/common";
import { MatIconRegistry } from "@angular/material/icon";
import {
  DomSanitizer,
  provideClientHydration,
  withIncrementalHydration,
} from "@angular/platform-browser";
import { AppConsts } from "@shared/AppConsts";
// this loads all the locals, it's not efficient but the alternative is load lots of different apps.
// angular says we should have 1 app for 1 language but that's a pain to set up.
import "@angular/common/locales/global/en";
import "@angular/common/locales/global/en-GB";
import "@angular/common/locales/global/es";
import "@angular/common/locales/global/ja";
import { TemplatePageTitleStrategy } from "@shared/title-strategy";
export function getAppConfig(routes: Routes): ApplicationConfig {
  return {
    providers: [
      // this enables the server to render, and the client to get the rendered version instead of rendering locally.
      provideClientHydration(withIncrementalHydration()),
      //provideZoneChangeDetection({ eventCoalescing: true }), // in v19 default, but apparently need to use bootstrapoptions instead  https://angular.dev/api/core/provideZoneChangeDetection
      provideRouter(
        routes,
        withComponentInputBinding(),
        withInMemoryScrolling({
          scrollPositionRestoration: "enabled", // when going back, remember where
          anchorScrolling: "enabled", // allow fragments to be used, like on the schedule page
        })
      ),
      // we need animations sometimes, but this code is for component bootstrapped based apps
      //provideAnimationsAsync(),
      // needed because they are not really ready for standalone or require options setting on root
      importProvidersFrom([
        CalendarModule.forRoot({
          provide: DateAdapter,
          useFactory: adapterFactory,
        }),
        NgxDaterangepickerMd.forRoot({ firstDay: 1 }),
        Angular2PromiseButtonModule.forRoot({
          spinnerTpl:
            '<div class="cssload-container"><div class="cssload-speeding-wheel"></div></div>',
          disableBtn: true,
        }),
      ]),
      { provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl },

      { provide: TitleStrategy, useExisting: TemplatePageTitleStrategy },
      { provide: RECAPTCHA_V3_SITE_KEY, useValue: "<YOUR_SITE_KEY>" },
      {
        provide: ErrorStateMatcher,
        useClass: ShowOnDirtyErrorStateMatcher,
      },
      provideAnimations(), // needed by material
      provideAppInitializer(() => initIcons()),

      {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
          appearance: "outline",
        },
      },
      {
        provide: MAT_CARD_CONFIG,
        useValue: {
          appearance: "outlined",
        },
      },

      provideNativeDateAdapter(),
      provideHttpClient(withInterceptorsFromDi(), withFetch()),
    ],
  };
}

// only what is absolutely needed to load the landing page
export function initIcons() {
  let matIconRegistry = inject(MatIconRegistry);
  let domSanitizer = inject(DomSanitizer);
  matIconRegistry.addSvgIconLiteral(
    `google`,
    domSanitizer.bypassSecurityTrustHtml(
      `<svg width="2443" height="2500" viewBox="0 0 256 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"/><path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"/><path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"/><path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EB4335"/></svg>`
    )
  );
  matIconRegistry.addSvgIconLiteral(
    `microsoft`,
    domSanitizer.bypassSecurityTrustHtml(
      `<svg enable-background="new 0 0 2499.6 2500" viewBox="0 0 2499.6 2500" xmlns="http://www.w3.org/2000/svg"><path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b"/><path d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z" fill="#80cc28"/><path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef"/><path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09"/></svg>`
    )
  );
  matIconRegistry.addSvgIcon(
    `stripe`,
    domSanitizer.bypassSecurityTrustResourceUrl(
      "../assets/images/Stripe wordmark - blurple.svg"
    )
  );
  matIconRegistry.addSvgIcon(
    `zoom`,
    domSanitizer.bypassSecurityTrustResourceUrl(
      "../assets/images/zoomus-icon.svg"
    )
  );
  matIconRegistry.addSvgIcon(
    `calendrc`,
    domSanitizer.bypassSecurityTrustResourceUrl(
      "../assets/images/perfect-circle.svg"
    )
  );
}

export function getBaseHref(platformLocation: PlatformLocation): string {
  const baseUrl = platformLocation.getBaseHrefFromDOM();
  if (baseUrl) {
    return baseUrl;
  }

  return "/";
}

export function shouldLoadLocale(): boolean {
  return (
    abp.localization.currentLanguage.name &&
    abp.localization.currentLanguage.name !== "en-US"
  );
}
export function convertAbpLocaleToAngularLocale(locale: string): string {
  if (!AppConsts.localeMappings) {
    return locale;
  }

  const localeMappings = AppConsts.localeMappings.find(
    (mapping) => mapping.from === locale
  );
  if (localeMappings) {
    return localeMappings.to;
  }

  return locale;
}
