import { Routes } from "@angular/router";
import { OrganizationsCacheService } from "@shared/cache-services/organizations-cache.service";
import { PendingChangesGuard } from "@shared/dirty-check/dirty-check.guard";

export const bookingpageeditorroots: Routes = [
  {
    path: "create",
    loadComponent: () =>
      import(
        "@app/features/event-types/calendar-manager-edit/calendar-manager-edit.component"
      ).then((m) => m.CalendarManagerEditComponent),
    resolve: {
      a: OrganizationsCacheService,
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: "edit/:url",
    loadComponent: () =>
      import(
        "@app/features/event-types/calendar-manager-edit/calendar-manager-edit.component"
      ).then((m) => m.CalendarManagerEditComponent),
    resolve: {
      a: OrganizationsCacheService,
    },
    canDeactivate: [PendingChangesGuard],
  },
];
