import { Routes } from "@angular/router";

export const featuresRoutes: Routes = [
  {
    title: $localize`Features`,
    path: "features",
    pathMatch: "full",
    loadComponent: () =>
      import("./features.component").then((m) => m.FeaturesComponent),
  },
  {
    path: "features",
    children: [
      {
        path: "calendar-integrations",
        loadComponent: () =>
          import("./calendar-integrations.component").then(
            (m) => m.CalendarIntegrationsComponent
          ),
      },
      {
        path: "time-zone-intelligence",
        loadComponent: () =>
          import("./time-zone-intelligence.component").then(
            (m) => m.TimeZoneIntelligenceComponent
          ),
      },
      {
        path: "minimum-notice-period",
        loadComponent: () =>
          import("./minimum-notice-period.component").then(
            (m) => m.MinimumNoticePeriodComponent
          ),
      },
      {
        path: "website-embedding",
        loadComponent: () =>
          import("./website-embedding.component").then(
            (m) => m.WebsiteEmbeddingComponent
          ),
      },
      {
        path: "preparation-questions",
        loadComponent: () =>
          import("./preparation-questions.component").then(
            (m) => m.PreparationQuestionsComponent
          ),
      },
      {
        path: "personalized-links",
        loadComponent: () =>
          import("./personalized-links.component").then(
            (m) => m.PersonalizedLinksComponent
          ),
      },
      {
        path: "groups-multiple-attendees",
        loadComponent: () =>
          import("./groups-multiple-attendees.component").then(
            (m) => m.GroupsMultipleAttendeesComponent
          ),
      },
      {
        path: "multiple-hosts",
        loadComponent: () =>
          import("./multiple-hosts.component").then(
            (m) => m.MultipleHostsComponent
          ),
      },
      {
        path: "round-robin",
        loadComponent: () =>
          import("./round-robin.component").then((m) => m.RoundRobinComponent),
      },
      {
        path: "collect-payments",
        loadComponent: () =>
          import("./collect-payments.component").then(
            (m) => m.CollectPaymentsComponent
          ),
      },
      {
        path: "chrome-extension",
        loadComponent: () =>
          import("./chrome-extension.component").then(
            (m) => m.ChromeExtensionComponent
          ),
      },
      {
        path: "gmail-add-on",
        loadComponent: () =>
          import("./gmail-add-on.component").then((m) => m.GmailAddOnComponent),
      },
      {
        path: "COVID19-carpool-testing",
        loadComponent: () =>
          import("./covid19-carpool-testing.component").then(
            (m) => m.Covid19CarpoolTestingComponent
          ),
      },
    ],
  },
];
