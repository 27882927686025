import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Meta } from "@angular/platform-browser";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { environment } from "@environments/environment";
import { take } from "rxjs/operators";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

@Component({
  selector: "app-root",
  template: `<router-outlet></router-outlet>`,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  public loading: boolean = false;
  constructor(
    private meta: Meta,
    private _activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // check if we have a forgotten password code
    // we take one because this can fire multiple times
    this._activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params["ForgotPassword"] != undefined) {
        console.log("opening reset password modal");
        this.dialog.open(ResetPasswordComponent, {
          data: {
            code: params["ForgotPassword"],
          },
        });
      }
    });

    if (!environment.production) {
      this.meta.addTag({ name: "robots", content: "noindex" });
    }
  }
}
