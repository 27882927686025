import { Routes } from "@angular/router";
import { AppRouteGuard } from "@shared/auth/auth-route-guard";

export const adminRoutes: Routes = [
  {
    title: $localize`Admin`,
    path: "admin",
    loadComponent: () =>
      import("@app/admin/admin.component").then((m) => m.AdminComponent),
    canActivate: [AppRouteGuard],
    children: [
      {
        path: "",
        redirectTo: "users",
        pathMatch: "full",
      },
      {
        path: "users",
        loadComponent: () =>
          import("@app/admin-users/admin-users.component").then(
            (m) => m.AdminUsersComponent
          ),
        canActivate: [AppRouteGuard],
        data: { permission: "Pages.Users" },
      },
      {
        path: "users/:id/edit",
        loadComponent: () =>
          import("@app/admin-edit-user/admin-edit-user.component").then(
            (m) => m.AdminEditUserComponent
          ),
        canActivate: [AppRouteGuard],
        data: { permission: "Pages.Users" },
      },
      {
        path: "event-types",
        loadComponent: () =>
          import(
            "@app/admin-eventtypes-list/admin-eventtypes-list.component"
          ).then((m) => m.AdminEventtypesListComponent),
        canActivate: [AppRouteGuard],
        data: { permission: "Pages.EventTypes" },
      },
      {
        path: "events",
        loadComponent: () =>
          import("@app/admin-event-list/admin-event-list.component").then(
            (m) => m.AdminEventListComponent
          ),
        canActivate: [AppRouteGuard],
        data: { permission: "Pages.Events" },
      },
      {
        path: "audit-log",
        loadComponent: () =>
          import("@app/admin-auditlog-list/admin-auditlog-list.component").then(
            (m) => m.AdminAuditlogListComponent
          ),
        canActivate: [AppRouteGuard],
        data: { permission: "Pages.AuditLog" },
      },
      {
        path: "background-jobs",
        loadComponent: () =>
          import(
            "@app/admin-backgroundjobs-list/admin-backgroundjobs-list.component"
          ).then((m) => m.AdminBackgroundjobsListComponent),
        canActivate: [AppRouteGuard],
        data: { permission: "Pages.BackgroundJobs" },
      },
      {
        path: "signalr",
        loadComponent: () =>
          import("../admin-signalr/admin-signalr.component").then(
            (m) => m.AdminSignalrComponent
          ),
        canActivate: [AppRouteGuard],
        data: { permission: "Pages.Admin" },
      },
      {
        path: "organizations",
        loadComponent: () =>
          import(
            "@app/admin-organizations-list/admin-organizations-list.component"
          ).then((m) => m.AdminOrganizationListComponent),
        canActivate: [AppRouteGuard],
        data: { permission: "Pages.Organizations" },
      },
    ],
  },
];
