<h2 mat-dialog-title i18n>Reset Password</h2>

<mat-dialog-content>
  <form [formGroup]="passwordResetForm" (ngSubmit)="passwordResetFormSubmit()">
    <!--Body-->

    <mat-form-field>
      <mat-icon matPrefix>lock</mat-icon>
      <mat-label i18n>New password</mat-label>
      <input
        matInput
        required
        autocomplete="new-password"
        type="password"
        [formControl]="passwordResetFormPassword"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-icon matPrefix>lock</mat-icon>
      <mat-label i18n>Confirm password</mat-label>
      <input
        matInput
        required
        autocomplete="new-password"
        type="password"
        [formControl]="passwordResetFormCPassword"
      />
    </mat-form-field>

    <div
      *ngIf="
        passwordResetForm.errors?.mismatch &&
        (passwordResetForm.touched || passwordResetForm.dirty) &&
        (passwordResetFormCPassword.touched || passwordResetFormCPassword.dirty)
      "
      class="cross-validation-error-message alert alert-danger"
      i18n
    >
      Passwords do not match.
    </div>

    <button
      type="submit"
      mat-flat-button
      [disabled]="!passwordResetForm.valid"
      i18n
    >
      Change password
    </button>
  </form>
</mat-dialog-content>
