import { Routes } from "@angular/router";
import { adminRoutes } from "./admin/admin.routes";
import { dashboardRoutes } from "./dashboard/dashboard.routes";
import { landingPageroutes } from "./landing-page/landing-page.routes";
import { PersonalPageResolver } from "./personal-page/personal-page.resolver";
import { OrgInviteResolver } from "./signup/org-invite.resolver";

// since this is the top of the app, each of these should be completely different pages.
// we do not have many of them, but things like dedicated login page, special pages from links, the main homepage, dashboard.
// since app component is a blank shell, it's ok to treat these as separate pages
// basically things that are "wrapped in nothing" should be here
export const routes: Routes = [
  ...landingPageroutes,
  ...dashboardRoutes,
  ...adminRoutes,
  {
    title: $localize`Signup`,
    path: "app/signup/:id",

    loadComponent: () =>
      import("../app/signup/signup.component").then((m) => m.SignupComponent),
    resolve: { invitedata: OrgInviteResolver },
  },
  {
    title: $localize`Login`,
    path: "login",
    loadComponent: () =>
      import("../app/login/login.component").then((m) => m.LoginComponent),
  },
  {
    title: $localize`Privacy Policy`,
    path: "privacy-policy",
    loadComponent: () =>
      import("../app/privacy-policy/privacy-policy.component").then(
        (m) => m.PrivacyPolicyComponent
      ),
  },
  {
    title: $localize`Terms`,
    path: "terms",
    loadComponent: () =>
      import("../app/terms/terms.component").then((m) => m.TermsComponent),
  },
  {
    title: $localize`End User`,
    path: "end-user",
    loadComponent: () =>
      import("../app/end-user/end-user.component").then(
        (m) => m.EndUserComponent
      ),
  },
  {
    title: $localize`Personal Page`,
    path: ":username",
    resolve: { personalPageData: PersonalPageResolver },
    loadComponent: () =>
      import("../app/personal-page/personal-page.component").then(
        (m) => m.PersonalPageComponent
      ),
  },

  // default route to homepage when nothing else matches
  {
    redirectTo: "",
    path: "**",
  },
];
