import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import {
  PersonalPageDto,
  PersonalPageGuestServiceProxy,
} from "@shared/service-proxies/PersonalPageGuest-client";

import { catchError, of, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PersonalPageResolver implements Resolve<PersonalPageDto> {
  constructor(
    private serv: PersonalPageGuestServiceProxy,
    private router: Router
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.serv.get(route.params.username).pipe(
      catchError(() => {
        this.router.navigate(["/"]);
        return of(null);
      }),
      tap((data) => {
        if (!data || !data.eventTypes || data.eventTypes.length === 0) {
          this.router.navigate(["/"]);
        }
      })
    );
  }
}
