declare global {
  interface Date {
    setDay(dayOfWeek): void;
    yesterday(): Date;
    setToBeginningOfDay(): void;
    beginningOfDay(): Date;
    beginningOfToday(): Date;
    setToBeginningOfMonth(): void;
    beginningOfMonth(): Date;
    setToEndOfMonth(): void;
    endOfMonth(): Date;
    isSameDayAs(dateB: Date): boolean;
    addDays(days: number): Date;
    range(start: Date, end: Date): Date[];
  }
}

Date.prototype.setDay = function (dayOfWeek) {
  this.setDate(this.getDate() - this.getDay() + dayOfWeek);
};

Date.prototype.yesterday = function () {
  return new Date(Date.now() - 1000 * 60 * 60 * 24);
};

Date.prototype.isSameDayAs = function (dateB) {
  return (
    this.getDate() == dateB.getDate() &&
    this.getFullYear() == dateB.getFullYear() &&
    this.getMonth() == dateB.getMonth()
  );
};

Date.prototype.setToBeginningOfMonth = function () {
  this.setSeconds(0);
  this.setMilliseconds(0);
  this.setMinutes(0);
  this.setHours(0);
  this.setDate(1);
};

Date.prototype.beginningOfMonth = function () {
  var a = new Date(this);
  a.setToBeginningOfMonth();
  return a;
};

Date.prototype.setToEndOfMonth = function () {
  this.setToBeginningOfMonth();
  this.setMonth(this.getMonth() + 1);
};

Date.prototype.endOfMonth = function () {
  var a = new Date(this);
  a.setToEndOfMonth();
  return a;
};

Date.prototype.setToBeginningOfDay = function () {
  this.setSeconds(0);
  this.setMilliseconds(0);
  this.setMinutes(0);
  this.setHours(0);
};
Date.prototype.beginningOfDay = function () {
  var a = new Date(this);
  a.setToBeginningOfDay();
  return a;
};

Date.prototype.beginningOfToday = function () {
  var a = new Date(Date.now());
  a.setToBeginningOfDay();
  return a;
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

// gets all the dates in a range
Date.prototype.range = function (start, end) {
  var dates = [];
  var current = new Date(start);
  while (current <= end) {
    dates.push(new Date(current));
    current = current.addDays(1);
  }
  return dates;
};

export {};
