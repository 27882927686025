import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";

@Injectable({
  providedIn: "root",
})
export class SwUpdaterService {
  updateAvailable = false;
  constructor(public updates: SwUpdate) {}
  start() {
    this.updates.versionUpdates.subscribe((event) => {
      if (event.type == "VERSION_READY") {
        console.log(`Current app version: ${event.currentVersion.hash}`);
        console.log(
          `New app version ready for use: ${event.latestVersion.hash}`
        );

        document.location.reload();
        console.log("Updates from SW ready, reload to see them.");
      }
    });
    this.updates.unrecoverable.subscribe((event) => {
      document.location.reload();
    });
    this.updates.checkForUpdate();
  }

  //   abp.message.error(

  //     'Reload to get the latest version','Calendr is out of date',
  //     {buttons : {reload : {text : "reload" , value : "reload"}}}

  // ).then((value) => { if (value == "reload") document.location.reload() });
}
