declare global {
    interface String {
        toTimespan(): Date;
    }
}

String.prototype.toTimespan = function () {
    // input is like "15:00"

    var thestring = this;
    var res = new Date(0);
    res.setHours(parseInt(thestring.substr(0, 2)));
    res.setMinutes(parseInt(thestring.substr(3, 2)));

    return res;


};

export { };

