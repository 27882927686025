import { Routes } from "@angular/router";

export const solutionsRoutes: Routes = [
  {
    title: $localize`Solutions`,
    path: "solutions",
    loadComponent: () =>
      import("./solutions.component").then((m) => m.SolutionsComponent),
    pathMatch: "full",
  },
  {
    path: "solutions",
    children: [
      {
        path: "COVID19-vaccination",
        loadComponent: () =>
          import("./covid19-vaccination.component").then(
            (m) => m.COVID19VaccinationComponent
          ),
      },
      {
        path: "customer-success-account-management",
        loadComponent: () =>
          import("./customer-success-account-management.component").then(
            (m) => m.CustomerSuccessAccountManagementComponent
          ),
      },
      {
        path: "education",
        loadComponent: () =>
          import("./education.component").then((m) => m.EducationComponent),
      },
      {
        path: "freelancers-consultants",
        loadComponent: () =>
          import("./freelancers-consultants.component").then(
            (m) => m.FreelancersConsultantsComponent
          ),
      },
      {
        path: "hair-beauty",
        loadComponent: () =>
          import("./hair-beauty.component").then((m) => m.HairBeautyComponent),
      },
      {
        path: "leaders-entrepreneurs",
        loadComponent: () =>
          import("./leaders-entrepreneurs.component").then(
            (m) => m.LeadersEntrepreneursComponent
          ),
      },
      {
        path: "real-estate",
        loadComponent: () =>
          import("./real-estate.component").then((m) => m.RealEstateComponent),
      },
      {
        path: "recruitment",
        loadComponent: () =>
          import("./recruitment.component").then((m) => m.RecruitmentComponent),
      },
      {
        path: "restaurants-hospitality",
        loadComponent: () =>
          import("./restaurants.component").then((m) => m.RestaurantsComponent),
      },
      {
        path: "sales-marketing",
        loadComponent: () =>
          import("./sales-marketing.component").then(
            (m) => m.SalesMarketingComponent
          ),
      },
    ],
  },
];
