import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import {
  OrganizationInvitationSignupDto,
  OrganizationInvitationsServiceProxy,
} from "@shared/service-proxies/OrganizationInvitations-client";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class OrgInviteResolver
  implements Resolve<OrganizationInvitationSignupDto>
{
  constructor(
    private service: OrganizationInvitationsServiceProxy,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.service.getForSignup(route.params["id"]).pipe(
      tap((e) => {
        if (e.email == "") this.router.navigate(["error"]);
      })
    );
  }
}
