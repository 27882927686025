import { inject } from "@angular/core";
import { AppSessionService } from "../session/app-session.service";

import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { PermissionCheckerService } from "abp-ng2-module";
import { map } from "rxjs/operators";

export const AppRouteGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): MaybeAsync<GuardResult> => {
  let _permissionChecker = inject(PermissionCheckerService);
  let _router = inject(Router);
  let _sessionService = inject(AppSessionService);

  return _sessionService.user$.pipe(
    map((user) => {
      if (!user) {
        _router.navigate(["/"]);
        return false;
      }

      if (!route.data || !route.data["permission"]) {
        return true;
      }

      if (_permissionChecker.isGranted(route.data["permission"])) {
        return true;
      }

      console.log("Permission denied");
      _router.navigate([selectBestRoute()]);
      return false;
    })
  );
};

function selectBestRoute(): string {
  if (!this._sessionService.user) {
    return "/";
  }

  if (this._permissionChecker.isGranted("Pages.Users")) {
    return "/app/admin/users";
  }

  return "/app/home";
}
