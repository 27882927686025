import { DOCUMENT } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  ApplicationConfig,
  inject,
  LOCALE_ID,
  provideAppInitializer,
} from "@angular/core";
import { LocaleService } from "@app/locale.service";
import { environment } from "@environments/environment";
import { getCookieValue } from "@shared/utils/cookies";
import { AbpHttpInterceptor } from "abp-ng2-module";

const languages = ["en", "en-GB", "es", "ja"];
// should only be run on browser side
export function getCurrentLanguage(): string {
  // if the URL has a lang parameter, use that
  let lang = new URLSearchParams(window.location.search).get("lang");

  // if web have abp, use that language cookie is set, we use that because it means we have a user.
  if (!lang) {
    lang = getCookieValue("Abp.Localization.CultureName") || lang;
  }

  if (lang) {
    console.log("LOCALE ID set from cookie/langurl to " + lang);
    return lang;
  } else {
    lang = languages[0]; // default to first language in the array
    // first first available language
    for (let i of window.navigator.languages) {
      if (
        languages.indexOf(i) > -1 ||
        languages.indexOf(i.split("-")[0]) > -1
      ) {
        lang = i;
        break;
      }
    }
  }

  // // update cookie with detected language
  // abp.utils.setCookieValue(
  //   "Abp.Localization.CultureName",
  //   lang,
  //   new Date(new Date().getTime() + 5 * 365 * 86400000), // 5 year
  //   abp.appPath,
  //   "." + environment.domain
  // );

  console.log("LOCALE ID set to " + lang);
  return lang;
}

export function addLocalRefsToIndex(): void {
  // set link in header for each language
  const docservice = inject(DOCUMENT);
  languages.forEach((lang) => {
    let link: HTMLLinkElement = docservice.createElement("link");
    link.setAttribute("rel", "alternate");
    link.setAttribute(
      "href",
      environment.hostname + "?lang=" + lang // updated variable name from l.name to lang
    );
    link.setAttribute("hreflang", lang); // updated variable name from l.name to lang
    docservice.head.appendChild(link);
  });
}
export function loadLocale(): Promise<void> {
  const l = inject(LOCALE_ID);
  console.log("loading locale " + l);
  return inject(LocaleService).loadLanguage(l.split("-")[0]);
}

export const browserConfig: ApplicationConfig = {
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    // provideAppInitializer(() => {
    //   inject(GoogleTagManagerService).addGtmToDom();
    // }),
    // provideAppInitializer(() => {
    //   addLocalRefsToIndex();
    // }),
    {
      provide: LOCALE_ID,
      useFactory: getCurrentLanguage,
    },
    provideAppInitializer(loadLocale),
    // do we want to do this? It will load clarity up
    // ClarityService,
    // importProvidersFrom([
    //   GoogleTagManagerModule.forRoot({
    //     id: environment.gtm,
    //   }),
    // ]),
  ],
};
