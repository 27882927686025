/// <reference types="@angular/localize" />
import {
  enableProdMode,
  importProvidersFrom,
  inject,
  mergeApplicationConfig,
  provideAppInitializer,
} from "@angular/core";
import { environment } from "@env/environment";

// Extensions must be imported here even though they are not directly used in this file.
import { bootstrapApplication } from "@angular/platform-browser";
import { ServiceWorkerModule } from "@angular/service-worker";
import { routes } from "@app/app.routes";
import { SwUpdaterService } from "@app/sw-updater.service";
import "@shared/helpers/ArrayHelper";
import "@shared/helpers/DateHelpers";
import "@shared/helpers/StringExtensions";
import "@shared/helpers/TimeSpanToString";
import { AppComponent } from "./app/app.component";
import { browserConfig } from "./shared.browser-config";
import { getAppConfig } from "./shared.main";

// locales

if (environment.production) {
  enableProdMode();
}

let baseconfig = getAppConfig(routes);

//// main app only config
// make worker only available in production
if (environment.production || environment.test) {
  baseconfig.providers.push(
    importProvidersFrom([
      ServiceWorkerModule.register("ngsw-worker.js", {
        enabled: true,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: "registerWhenStable:30000",
      }),
    ])
  );

  baseconfig.providers.push(
    provideAppInitializer(() => {
      const initializerFn = (
        (ds: SwUpdaterService) => () =>
          ds.start()
      )(inject(SwUpdaterService));
      return initializerFn();
    })
  );
}

bootstrapApplication(
  AppComponent,
  mergeApplicationConfig(baseconfig, browserConfig)
);
