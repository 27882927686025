import { Routes } from "@angular/router";
import { featuresRoutes } from "./features/features.routes";
import { solutionsRoutes } from "./solutions/solutions.routes";

// at the top is always the shell
export const landingPageroutes: Routes = [
  {
    title: $localize`Home`,
    path: "",
    loadComponent: () =>
      import("./landing-page.component").then((m) => m.LandingpageComponent),
    children: [
      {
        title: $localize`Home`,
        path: "",
        loadComponent: () =>
          import("./home-page/home-page.component").then(
            (m) => m.HomePageComponent
          ),
      },
      {
        title: $localize`Pricing`,
        path: "pages/pricing",
        loadComponent: () =>
          import("./components/pricing/pricing.component").then(
            (m) => m.PricingComponent
          ),
      },
      {
        title: $localize`FAQ`,
        path: "pages/faq",
        loadComponent: () =>
          import("@app/faq/faq.component").then((m) => m.FaqComponent),
      },
      ...featuresRoutes,
      // for all other pages, we delegate to the sales module
      ...solutionsRoutes,
      {
        title: $localize`Signup`,
        path: "signup",
        loadComponent: () =>
          import("../email-signup/email-signup.component").then(
            (m) => m.EmailSignupComponent
          ),
      },
    ],
  },
];
