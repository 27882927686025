import { Routes } from "@angular/router";
import { bookingpageeditorroots } from "@app/calendar-manager/calendar-manager.routes";
import { AppRouteGuard } from "@shared/auth/auth-route-guard";
import { AppSessionService } from "@shared/session/app-session.service";

export const dashboardRoutes: Routes = [
  {
    title: $localize`Dashboard`,
    path: "dashboard",
    loadComponent: () =>
      import("./dashboard.component").then((m) => m.DashboardComponent),
    canActivate: [AppRouteGuard],
    resolve: { appsession: AppSessionService },
    children: [
      {
        path: "",
        redirectTo: "calendars",
        pathMatch: "full",
      },
      {
        path: "notifications",
        loadComponent: () =>
          import("@app/notifications-page/notifications-page.component").then(
            (m) => m.NotificationsPageComponent
          ),
        title: $localize`Notifications`,
      },
      {
        path: "settings/profile",
        loadComponent: () =>
          import(
            "../user-settings-profile/user-settings-profile.component"
          ).then((m) => m.UserSettingsProfileComponent),
        title: $localize`Profile`,
      },
      {
        path: "organization",
        loadComponent: () =>
          import(
            "../features/organizations/organization/organization.component"
          ).then((m) => m.OrganizationComponent),
        title: $localize`Organization`,
      },
      {
        path: "calendar-sync",
        loadComponent: () =>
          import(
            "@app/external-calendar-selector/external-calendar-selector.component"
          ).then((m) => m.ExternalCalendarSelectorComponent),
        title: $localize`Calendar Sync`,
      },
      {
        path: "availability",
        loadComponent: () =>
          import(
            "projects/home/availability-settings/availability-settings.component"
          ).then((m) => m.AvailabilitySettingsComponent),
        title: $localize`Availability`,
      },
      {
        path: "integrations",
        loadComponent: () =>
          import("@app/connections/connections.component").then(
            (m) => m.ConnectionsComponent
          ),
        title: $localize`Integrations`,
      },
      {
        path: "calendars",
        loadComponent: () =>
          import("@app/calendar-manager/calendar-manager.component").then(
            (m) => m.CalendarManagerComponent
          ),
        title: $localize`Booking Pages`,
      },
      {
        path: "schedule",
        loadComponent: () =>
          import("@app/events-manager/events-manager.component").then(
            (m) => m.EventsManagerComponent
          ),
        title: $localize`Schedule`,
      },
    ],
  },
  {
    path: "onboarding",
    loadComponent: () =>
      import("../onboarding/onboarding.component").then(
        (m) => m.OnboardingComponent
      ),
    pathMatch: "full",
    title: $localize`Onboarding`,
    resolve: { appsession: AppSessionService },
  },
  // this is a special case because we do not want the dashboard shell on this page since its so big
  {
    path: "dashboard/calendars",
    children: bookingpageeditorroots,
    resolve: { appsession: AppSessionService },
  },
];
