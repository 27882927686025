import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import {
  AccountServiceProxy,
  ResetPasswordInput,
} from "@shared/service-proxies/Account-client";

import { PasswordMatchValidatorFactory } from "@shared/validators/PasswordSameValidator";
import { MessageService } from "abp-ng2-module";
import { Angular2PromiseButtonModule } from "angular2-promise-buttons";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    Angular2PromiseButtonModule,
    CommonModule,
    MatDialogModule,
  ],
})
export class ResetPasswordComponent {
  passwordResetFormPassword = new FormControl("");
  passwordResetFormCPassword = new FormControl("");
  passwordResetForm: FormGroup = new FormGroup(
    {
      passwordResetFormPassword: this.passwordResetFormPassword,
      passwordResetFormCPassword: this.passwordResetFormCPassword,
    },
    {
      validators: PasswordMatchValidatorFactory(
        "passwordResetFormPassword",
        "passwordResetFormCPassword"
      ),
    }
  );

  code: string;
  constructor(
    private accountService: AccountServiceProxy,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    private message: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: { code: string }
  ) {
    this.code = data.code;
  }

  passwordResetFormSubmit() {
    this.accountService
      .resetPassword(
        new ResetPasswordInput({
          password: this.passwordResetFormCPassword.value,
          resetPasswordCode: this.code,
        })
      )
      .subscribe((res) => {
        if (res) {
          this.dialogRef.close(true);
          this.message.success("Password change successful. Please log in");
        }
      });
  }

  closeme() {
    new Promise(() => {
      this.dialogRef.close(true);
    }).then(() => {});
  }
}
